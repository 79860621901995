import IntersectionHydrator from '@netc/intersection-hydrator';
import { registrations } from '../registrations';
new IntersectionHydrator(registrations);
const ServiceWorkerPath = '/sitefiles/next-gen/dist/sw.js';
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker
            .register(ServiceWorkerPath, {
            scope: '/',
        })
            .then(function (registration) {
            // Registration was successful
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function (err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}
